<template>
  <loader v-if="isLoadingSettings"/>
  <div v-else class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Settings</h3>
        </div>
      </div>

      <form v-on:submit="onSave">
        <div class="kt-portlet__body kt-portlet__body--fluid">
          <div class="kt-form kt-form--label-right w-100">

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Guide Button label:
              </label>
              <div class="col-lg-9 col-xl-6">
                <input
                    title="Write Guide Button label."
                    class="form-control"
                    v-model="settings.label"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Guide Button color:</label>
              <div class="col-lg-9 col-xl-6">
                <ColorPicker :onConfirm="(value) => {settings.button_color = value}"
                             :color="settings.button_color" title="Guide Button color"/>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Guide Button text color:</label>
              <div class="col-lg-9 col-xl-6">
                <ColorPicker :onConfirm="(value) => {settings.text_color = value}"
                             :color="settings.text_color" title="Guide Button text color"/>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Hide Guide Units Selector on site widget:</label>
              <div class="col-lg-9 col-xl-6">
                <div class="kt-input-icon kt-input-icon--right">
                  <div class="kt-switch_block ">
                        <span
                            :class="`kt-switch kt-switch--sm`"
                            @click="e => onToggle(e, 'hide_units')"
                        >
                          <label class="mb-0">
                            <input
                                type="checkbox"
                                :checked="!!settings.hide_units"
                                name=""
                            />
                            <span/>
                          </label>
                        </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Autoset Guide Units Selector by visitor location:</label>
              <div class="col-lg-9 col-xl-6">
                <div class="kt-input-icon kt-input-icon--right">
                  <div class="kt-switch_block ">
                        <span
                            :class="`kt-switch kt-switch--sm`"
                            @click="e => onToggle(e, 'geo_units')"
                        >
                          <label class="mb-0">
                            <input
                                type="checkbox"
                                :checked="!!settings.geo_units"
                                name=""
                            />
                            <span/>
                          </label>
                        </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Widget Position:</label>
              <div class="col-lg-9 col-xl-6">
                <div class="radio-list d-flex flex-column">
                  <label
                      class="radio mr-auto">
                    <input
                        type="radio"
                        value="left"
                        v-model="settings.widget_position"
                    /> Left
                  </label>
                  <label
                      class="radio mr-auto">
                    <input
                        type="radio"
                        value="right"
                        v-model="settings.widget_position"
                    /> Right
                  </label>
                  <label
                      class="radio mr-auto">
                    <input
                        type="radio"
                        value="button"
                        v-model="settings.widget_position"
                    /> Add to Cart Button (hyperlink)
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Decimal places on unit conversions:</label>
              <div class="col-lg-9 col-xl-6 app-select">
                <v-select
                    :options="decimal_places_options"
                    v-model="settings.decimal_places"
                    :getOptionLabel="option => option.label"
                    :reduce="option => option.value"
                    :clearable="false"
                />
              </div>
            </div>


            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Table theme:</label>
              <div class="col-lg-9 col-xl-6 app-select">
                <v-select
                    :options="table_theme_option"
                    v-model="settings.table_theme"
                    :getOptionLabel="option => option.label"
                    :reduce="option => `${option.value}`"
                    :clearable="false"
                />
              </div>
            </div>


            <div class="row">
              <div class="col-xl-4 col-lg-3"></div>
              <div class="col-lg-9 col-xl-6 app-select">
                <img alt="preview" :src="`/images/tables/${settings.table_theme}.png`"/>
              </div>
            </div>

          </div>
        </div>
        <div class="kt-portlet__foot">
          <div class="kt-form__actions">
            <div class="row">
              <div class="col-xl-4 col-lg-3"></div>
              <div class="col-lg-9 col-xl-6">
                <button
                    type="submit"
                    id="createButton"
                    :disabled="isEditingSettings"
                    :class=" `btn btn-success ${isEditingSettings &&' kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark'}` "
                >
                  Save
                </button>&nbsp;
                <button
                    @click="onCancelClick()"
                    id="cancelButton"
                    type="button"
                    :class="`btn btn-secondary`"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from "vue-select";

import Loader from '../components/Loader';
import {LOAD_SETTINGS, EDIT} from '../store/modules/shop/types';
import {requestStatus} from "@/services/services";
import ColorPicker from '../components/ColorPicker';


export default {
  name: "Settings",
  components: {vSelect, Loader, ColorPicker},
  data: () => ({
    settings: {
      label: "",
      button_color: "",
      text_color: "",
      units_color: "",
      hide_units: false,
      geo_units : false,
      widget_position: "",
      decimal_places: 0,
      table_theme : "",
    },
    isLoadingSettings: false,
    isEditingSettings: false,
  }),

  computed: {
    ...mapState({
      shopSettings: state => state.shop.settings,
      status: state => state.shop.status
    }),
    decimal_places_options() {
      return [{
        label: '0 decimals',
        value: 0
      }, {
        label: '1 decimals',
        value: 1
      }, {
        label: '2 decimals',
        value: 2
      }]
    },

    table_theme_option() {
      return [{
        label: 'Default',
        value: "default"
      },{
        label: 'Rounded Blue',
        value: "rounded_blue"
      },{
        label: 'Dark',
        value: "dark"
      },{
        label: 'Dark header',
        value: "dark_header"
      },{
        label: 'Green',
        value: "green"
      },{
        label: 'Blue',
        value: "blue"
      },{
        label: 'Red',
        value: "red"
      },{
        label: 'Light blue',
        value: "light_blue"
      },{
        label: 'Purple',
        value: "purple"
      },{
        label: 'Red text',
        value: "red_text"
      },{
        label: 'Blue text',
        value: "blue_text"
      },
      ]
    },
  },

  methods: {
    ...mapActions({
      loadSettings: `shop/${LOAD_SETTINGS}`,
      onEdit: `shop/${EDIT}`,
    }),
    onSave(e) {
      e.preventDefault();
      this.onEdit(this.settings);
    },
    onCancelClick() {
      this.settings = {...this.shopSettings};
    },
    onToggle(e, field) {
      e.preventDefault();
      this.settings[field] = !this.settings[field];
    },

  },
  watch: {
    shopSettings(newValue) {
      this.settings = {...newValue};
    },
    status(newValue) {
      this.isLoadingSettings = newValue.load_settings === requestStatus.loading;
      this.isEditingSettings = newValue.edit_settings === requestStatus.loading;
      if (newValue.change_field === 'edit_settings' && newValue.edit_settings === requestStatus.success) {
        this.$toaster.success("Success update settings");
      }
    }
  },
  created: function () {
    this.loadSettings();
  }
}
</script>


<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>